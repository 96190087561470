import css from './instagram.module.css';

const Insta = ({ data }) => {
  console.log(data, 'Test Data');
  return (
    <div className={css.instaParent}>
      <div className={css.instaGrid}>
        {data.map(
          (e, i) =>
            e.media_type === 'IMAGE' && (
              <div className={css.ImageInstaContainer} key={i}>
                <a
                  href="https://www.instagram.com/vogueshots_/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={e.media_url}
                    width={400}
                    height={400}
                    alt={`Instagram_Image_${i}`}
                  />
                </a>
              </div>
            ),
        )}
      </div>
      <div className={css.followOnInstaBtn}>
        <a
          class={css.followOnInstaBtnLink}
          href="https://www.instagram.com/vogueshots_/?hl=en"
        >
          Follow us on Instagram
        </a>
      </div>
    </div>
  );
};

export default Insta;
