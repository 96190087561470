import css from "./header.module.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = ({ data, products, externalLinks, id, setid }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const [openMegaMenu, setOpenMegaMenu] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlemobilemenu = () => {
    document.querySelector(`#header_textContainer`).style.display = "flex";
    document.body.classList.add('is-scroll-locked');
  };

  const closeMobileMenu = () => {
    if (isMobile) {
      document.querySelector(`#header_textContainer`).style.display = "none";
      document.body.classList.remove('is-scroll-locked');
    }
  };

  const toggleMegaMenu = (slug) => {
    if (isMobile) {
      setOpenMegaMenu(openMegaMenu === slug ? null : slug);
    }
  };

  return (
    <div className={css.headerParent}>
      <div className={css.Container}>
        <div className={css.logoContainer}>
          <Link to="/">
            <img
              className={css.vogueShotLogo}
              src={data.logo.url}
              onClick={() => setid("")}
              alt="logo"
            />
          </Link>
        </div>
        <div className={css.hamburger} onClick={handlemobilemenu}>
          <img src="/menuicon.svg" alt="hamburger" />
        </div>
        <div className={css.HeaderTextContainer} id="header_textContainer">
          <div className={css.crossButton} onClick={closeMobileMenu}>
            X
          </div>
          {externalLinks &&
            externalLinks.map((e, i) => (
              <Link to={`/${e.slug}`} key={i}>
                <span
                  className={`${id === e.slug ? css.activeScreen : ''} ${css.HeaderText}`}
                  onClick={() => {
                    closeMobileMenu();
                    setid(e.slug);
                  }}
                >
                  {e.name}
                </span>
              </Link>
            ))}
          {data.content &&
            data.content.map((item, i) => (
              <div key={i} className={css.menuItem}>
                {item.type === 'megamenu' ? (
                  <div className={css.megaMenuContainer}>
                    <span 
                      className={`${id === item.slug ? css.activeScreen : ''} ${css.HeaderText}`}
                      onClick={() => toggleMegaMenu(item.slug)}
                    >
                      {item.name}
                    </span>
                    <div className={`${css.megaMenu} ${openMegaMenu === item.slug ? css.open : ''}`}>
                      {item.children.map((child, j) => (
                        <Link key={j} to={`/product/${child.slug}`}>
                          <span
                            className={`${id === child.slug ? css.activeScreen : ''} ${css.HeaderText}`}
                            onClick={() => {
                              closeMobileMenu();
                              setid(child.slug);
                            }}
                          >
                            {child.name}
                          </span>
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link to={`/${item.slug}`}>
                    <span
                      className={`${id === item.slug ? css.activeScreen : ''} ${css.HeaderText}`}
                      onClick={() => {
                        closeMobileMenu();
                        setid(item.slug);
                      }}
                    >
                      {item.name}
                    </span>
                  </Link>
                )}
              </div>
            ))}
          <div className={css.contactButton} onClick={() => setid("")}>
            <a className={css.contactButtonLink} href="https://wa.me/919920189579">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;