import css from "./description.module.css";

const DescriptionBox = ({ info, type = 0 }) => {
  if (!info || !info.image || !info.image.path) return null;

  // A function to determine if the file is a video based on its extension
  const isVideoFile = (path) => {
    const videoExtensions = ['.mp4', '.webm', '.ogg']; // Extend this list with other video formats as needed
    return videoExtensions.some(extension => path.endsWith(extension));
  };

  const isVideo = isVideoFile(info.image.path);

  return (
    <div className={css.descriptionparent}>
      <div className={type === 0 ? css.descriptionContainer : css.descriptionContainer2}>
        <div className={css.mobTitle} dangerouslySetInnerHTML={{ __html: info.title }} />
        <div className={css.imageContainer}>
          {isVideo ? (
            <video
              className={css.descriptionBoxVideo}
              src={info.image.path}
              alt="video"
              controls
              autoPlay
              muted
              playsInline
              preload="metadata" // Use preload for lazy loading
            />
          ) : (
            <img src={info.image.path} alt="img" loading="lazy" />
          )}
        </div>
        <div className={css.DescriptionBox}>
          <div className={css.descriptionTitle} dangerouslySetInnerHTML={{ __html: info.title }} />
          <div className={css.description} dangerouslySetInnerHTML={{ __html: info.description }} />
        </div>
      </div>
    </div>
  );
};

export default DescriptionBox;
