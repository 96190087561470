import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Header from './Components/Header/header';
import json from './data.json';
import Footer from './Components/Footer/footer';
import { ProductPage, HomePage, AboutPage } from './Components';

function App() {
  useEffect(() => {
    getMedia();
  }, []);
  const [id, setid] = useState('');
  const [insta, setInsta] = useState([]);

  const getMedia = async () => {
    const fetchInstagramData = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.statusText}`);
        }
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching Instagram data:", error.message);
        throw error;
      }
    };
  
    let allPosts = [];
    let nextPage = `https://graph.instagram.com/v12.0/me/media?fields=id,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${process.env.REACT_APP_InstaToken}`;
  
    try {
      while (allPosts.length < 9) {
        const data = await fetchInstagramData(nextPage);
        const posts = data.data.filter(post => post.media_type !== "VIDEO");
        console.log(`Fetched ${posts.length} posts on this page`, posts);
        allPosts = allPosts.concat(posts);
  
        // Check if there's a next page
        nextPage = data.paging && data.paging.next;
        console.log("Next page URL:", nextPage);
        if (!nextPage) break;
  
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 1-second delay
      }
  
      console.log("Total posts fetched:", allPosts.length);
      setInsta(allPosts.slice(0, 9));
    } catch (error) {
      console.error("Error in getMedia:", error);
    }
  };  

  return (
    <Router>
      <div className="App">
        <Header
          externalLinks={json.header.externalLinks}
          data={json.header}
          products={json.products}
          id={id}
          setid={setid}
        />
        <Routes>
          <Route
            path="/"
            element={<HomePage id={id} setid={setid} insta={insta} />}
          />
          <Route path="/product/:id" element={<ProductPage />} />
          <Route
            path="/About"
            element={
              <AboutPage
                teamInfo={json.team}
                aboutUsDestination={json.aboutUsDestination}
              />
            }
          />
        </Routes>
        <Footer data={json.products} />
      </div>
    </Router>
  );
}

export default App;
