import Brands from './Brands/brands';
import Clients from './ClientCounter/counter';
import Insta from './instagram/instagram';
import Scroller from './Scroller/scroller';
import DescriptionBox from './descriptionbox/description';
import Gallery from './gallery/gallery';
import json from '../data.json';
import { useParams } from 'react-router-dom';
import Aboutus from './About/about';
import ScrollerProduct from './ScrollerProducts/scroller';
import PostersSlider from './Poster/PosterSlider';
import Template from './Template/Template';
import "../App.css";
import TemplateSliderComponent from './TemplateSliderComponent/TemplateSliderComponent';
import HomepageBannerNew from './HomepageBannerNew/HomepageBannerNew';

const HomePage = ({ id, setid, insta }) => {
  return (
    <>
    <HomepageBannerNew />
      {/* <Scroller images={json.scrollerHomePage} id={id} setid={setid} /> */}
      {/* <PostersSlider data={[
          "/gallery/Rectangle 18.svg",
          "/gallery/Rectangle 19.svg",
          "/gallery/Rectangle 20.svg",
          "/gallery/Rectangle 18.svg",
          "/gallery/Rectangle 19.svg",
          "/gallery/Rectangle 20.svg"]} /> */}
      <Brands brands={json.brands} />
      <Clients />
      <Insta data={insta} />
    </>
  );
};
const ProductPage = ({}) => {
  const { id } = useParams();
  const productId = id;
  const productData = json.products.find(
    (product) => product.slug === productId,
  );

  return (
    <>
      <DescriptionBox info={productData.info} />
      {id === 'photobooth' && <TemplateSliderComponent />}
      <PostersSlider data={productData.gallery} />
      {/* <Template /> */}
    </>
  );
};
const AboutPage = () => {
  const teamInfo = json.team;
  console.log(teamInfo); // Debugging line to check the data structure
  const aboutUsDestination = json['aboutUsDestination'];

  return (
    <Aboutus teamInfo={teamInfo} aboutUsDestination={aboutUsDestination} />
  );
};


export { HomePage, ProductPage, AboutPage };
