import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './HomepageBannerNew.css';

const HomepageBannerNew = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const images = [
    {
      url: 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/Glam_Shot.jpeg',
      route: '/product/glamshot',
      title: 'Glamshot',
    },
    {
      url: '/homepage/LENTICULAR_homepage.GIF',
      route: '/product/lenticular',
      title: 'Lenticular',
    },
    {
      url: '/homepage/Engraving_homepage.JPG',
      route: '/product/engravings',
      title: 'Engravings',
    },
    {
      url: 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/photobooth.JPEG',
      route: '/product/photobooth',
      title: 'Classic Photobooth',
    },
    {
      url: '/homepage/INSTAX_homepage.JPG',
      route: '/product/instax',
      title: 'Instax',
    },
    { url: '/homepage/GIF.GIF', route: '/product/gif', title: 'GIF' },
    {
      url: 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/videos/Enclosed_booth/enclosed_booth_2.GIF',
      route: '/product/video-guest-book',
      title: 'Enclosed Booth',
    },
    {
      url: 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/branding_booth_header.jpg',
      route: '/product/video-guest-book',
      title: 'Branding Booth',
    },
    {
      url: '/homepage/video_guest_book_homepage.PNG',
      route: '/product/video-guest-book',
      title: 'Video Guestbook',
    },
    {
      url: 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/videos/Audio_guest_book/audio_2.GIF',
      route: '/product/photobooth',
      title: 'Audio Guestbook',
    },
    {
      url: 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/videos/Photobook/photobook-1.PNG',
      route: '/product/photobooth',
      title: 'Photobooth Book',
    },
    {
      url: '/homepage/Kardashian_homepage.PNG',
      route: '/product/Kardashian',
      title: 'Kardashian Booth',
    },
    {
      url: '/homepage/AI_BOOTH_homepage.JPG',
      route: '/product/ai',
      title: 'A.I',
    },

  ];

  return (
    <div>
      {isMobile ? (
        <div className="HomepageBannerNew_mobile-container">
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true, type: 'fraction' }}
            navigation={true}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div
                  className="HomepageBannerNew_slide-content"
                  onClick={() => navigate(image.route)}
                >
                  {/* Adding loading="lazy" for lazy loading */}
                  <img src={image.url} alt={`Slide ${index + 1}`} loading="lazy" />
                  <p className="HomepageBannerNew_slide-text">{image.title}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="HomepageBannerNew_desktop-container">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={0}
            slidesPerView={3}
            navigation={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div
                  className="HomepageBannerNew_image-container"
                  onClick={() => navigate(image.route)}
                >
                  {/* Adding loading="lazy" for lazy loading */}
                  <img src={image.url} alt={`Image ${index + 1}`} loading="lazy" />
                  <p className="HomepageBannerNew_image-text">{image.title}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default HomepageBannerNew;
