import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './TemplateSliderComponent.css';
import { Pagination } from 'swiper/modules';

const TemplateSliderComponent = () => {
  const [activeSet, setActiveSet] = useState(0);
  const [swiperKey, setSwiperKey] = useState(Date.now());

  const buttonNames = ['2x6', '6x2', '4x6', '6x4'];

  const imageSets = [
    ['https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/2x6(1).PNG', 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/2x6(2).PNG'],
    ['https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/2x6(3).PNG', 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/2x6(4).PNG'],
    ['https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/4x6(1).PNG', 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/4x6(2).PNG', 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/4x6(3).PNG'],
    ['https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/6x4(1).PNG', 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/6x4(2).PNG', 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/6x4(3).PNG', 'https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/6x4(4).PNG'],
  ];

  useEffect(() => {
    setSwiperKey(Date.now()); // Update key to reset Swiper instance
  }, [activeSet]);

  return (
    <div className='template-slider-component-button-image-container'>
      <div className="template-slider-component-button-container">
        {buttonNames.map((name, index) => (
          <button key={index} onClick={() => setActiveSet(index)} className={`template-slider-component-button ${activeSet === index ? 'active' : ''}`}>
            {name}
          </button>
        ))}
      </div>

      <Swiper className='template-slider-swiper'
        key={swiperKey}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {imageSets[activeSet].map((imageUrl, index) => (
          <SwiperSlide className="template-slider-swiper-slide" key={index}>
            <img 
              src={imageUrl} 
              alt={`Slide ${index}`} 
              style={{ width: '100%', objectFit: 'cover' }} 
              loading="lazy" // Lazy load images
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TemplateSliderComponent;
