import React, { useState } from 'react';
import Slider from 'react-slick';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional for a blur effect
import css from './slider.module.css';

const SliderComponent = ({ child }) => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const totalSlides = React.Children.count(child);

  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={css.nextArrow} onClick={onClick}>
        <img
          src="https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/icons/icons8-arrow-100.png"
          alt="Next"
        />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={css.prevArrow} onClick={onClick}>
        <img
          src="https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/icons/icons8-arrow-200.png"
          alt="Previous"
        />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (current) => setCurrentSlide(current + 1),
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={css.containerSlider}>
      <Slider {...settings}>
        {React.Children.map(child, (slide, index) => (
          <div key={index}>
            <LazyLoadImage
              alt={`Slide ${index}`}
              src={slide.props.src} // Ensure you pass the image source as prop in the child components
              effect="blur" // Optional effect for loading
              visibleByDefault={index < 3} // Load first 3 images by default
            />
          </div>
        ))}
      </Slider>
      <div className={css.slideCountDisplay}>
        {currentSlide} / {totalSlides}
      </div>
    </div>
  );
};

export default SliderComponent;
