import SliderComponent from "../Slider/slider";
import css from "./destination.module.css";

const Destination = ({ destinationInfo }) => {
  if (destinationInfo) {
    return (
      <div className={css.galleryParent}>
        <div
          className={css.galleryTitle}
        >Destinations</div>
        <div className={css.galleryContainer}>
        <SliderComponent child={destinationInfo &&
            destinationInfo.map((e, i) => (
              <div className={css.DestinationContainer}>
                <div className={css.overlay} /><img
                src={e.image}
                key={i}
                className={css.sliderImage}
              /><span className={css.destinationName}>{e.name}</span></div>
            ))}/>
        </div>
      </div>
    );
  }
};

export default Destination;
