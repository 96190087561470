import css from "./counter.module.css"
import React from "react"

const Clients = ()=>{
    return<div className={css.parentContainer}>
        <div className={css.Container}>
            <div className={css.Col}>
                <span className={css.displayFont}>500</span>
                <span>Happy Clients</span>
            </div>
            <div className={css.divider} />
            <div className={css.Col}>
                <span className={css.displayFont}>5+</span>
                <span>Years of experience</span>
            </div>
            <div className={css.divider} />
            <div className={css.Col}>
                <sapn className={css.displayFont}>&#8734;</sapn>
                <span>Memories Made</span>
            </div>
        </div>
    </div>
}

export default Clients