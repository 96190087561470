import React from "react";
import DescriptionBoxText from "../descriptionbox/descriptionTest";
import css from "./about.module.css";
import Destination from "../destinations/destination";
import Template from "../Template/Template.js";

const Aboutus = ({ teamInfo, aboutUsDestination }) => {
  return (
    <div className={css.aboutParent}>
      <div className={css.aboutContainer}>
        <p className={css.title}>Who we are / Meet the team</p>
        {teamInfo && teamInfo.map((e, i) => <DescriptionBoxText key={i} type={i % 2} info={e} />)}
      </div>
      <Destination destinationInfo={aboutUsDestination} />
      {/* <Template />  */}
      {/* Simple component for destination */}
    </div>
  );
};

export default Aboutus;
