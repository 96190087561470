import React, { useEffect, useRef } from 'react';
import './Template.css';
import Swiper from 'swiper';

import { EffectCube } from 'swiper/modules';
import { StoriesSlider, Stories, Story } from '../Story';
import '../Story/Core.scss';
import '../Story/stories-slider.css';
import data from '../../data.json'; // Import your JSON data

const Template = () => {
  const storiesSliderRef = useRef(null);

  useEffect(() => {
    const slider = document.querySelector('.demo-stories');
    let isDown = false;
    let startX;
    let scrollLeft;
  
    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
  
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
  
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
  
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  
  }, []);
  
  const openUserStories = (userIndex) => {
    const storiesSlider = storiesSliderRef.current;
    storiesSlider.el.classList.add('stories-slider-in');
    storiesSlider.enable();
    storiesSlider.slideTo(userIndex, 0);
  };

  const onCloseButtonClick = () => {
    const storiesSlider = storiesSliderRef.current;
    storiesSlider.disable();
    storiesSlider.el.classList.add('stories-slider-out');
  };

  useEffect(() => {
    const storiesSlider = storiesSliderRef.current;
    if (storiesSlider) {
      storiesSlider.el.addEventListener('animationend', () => {
        if (storiesSlider.el.classList.contains('stories-slider-out')) {
          storiesSlider.el.classList.remove('stories-slider-in');
          storiesSlider.el.classList.remove('stories-slider-out');
        }
      });
    }
  }, [storiesSliderRef]);

  const storiesData = data['destination-component']; // Use the 'stories-component' from imported data

  return (
    <div className={"check"}>
      <h1>Destinations</h1>
      <div className="demo-stories">
        {storiesData.map((userStories, userStoriesIndex) => (
          <a
            key={userStoriesIndex}
            href="#"
            onClick={() => openUserStories(userStoriesIndex)}
          >
            <span className="demo-stories-avatar">
              <img className="template-name-image"src={userStories.user.avatar} alt="" />
            </span>
            <span className="demo-stories-name">{userStories.user.name}</span>
          </a>
        ))}

        <StoriesSlider
          Swiper={Swiper}
          EffectCube={EffectCube}
          enabled={false}
          autoplayDuration={5000}
          onSlidesIndexesChange={(mainIndex, subIndex) =>
            console.log({ mainIndex, subIndex })
          }
          onStoriesSlider={(instance) => {
            storiesSliderRef.current = instance;
          }}
          onEnd={() => {
            storiesSliderRef.current.disable();
            storiesSliderRef.current.el.classList.add('stories-slider-out');
          }}
        >
          {storiesData.map((userStories, userStoriesIndex) => (
  <Stories key={userStoriesIndex}>
    {userStories.stories.map((story, storyIndex) => (
      <Story
        key={storyIndex}
        storyIndex={storyIndex + 1} // Plus 1 to start counting from 1
        userLink="#"
        avatar={<img src={userStories.user.avatar} alt="" />}
        name={userStories.user.name}
        classTemplateName={userStories.user.classTemplateName}
        date={story.date}
        closeButton
        onCloseButtonClick={onCloseButtonClick}
      >
        {story.image && (
          <img
            className={`template-content-image ${userStories.user.classTemplateName} ${storyIndex + 1}`}
            src={story.image}
            alt=""
          />
        )}
        {story.video && (
          <video src={story.video} playsInline preload="metadata" />
        )}
      </Story>
    ))}
  </Stories>
))}
        </StoriesSlider>
      </div>
    </div>
  );
};

export default Template;
